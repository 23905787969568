import React from "react"
import {
    ReactVideoPlayer
 } from "../components/reactPlayer"
const fixture = [
    {
        programName: "Command Healing ",
        type: 'command-healing',
        featuredImage: "/images/command-healing-min.jpg",
        audioFiles: [            
            {
                title: 'Command Healing - Session 1',
                image: "https://karinagrant.s3.eu-west-1.amazonaws.com/program/command-healing/command-healing-min.jpg",
                content: [
                    {
                        video: [
                            {
                                title: 'Session 1',
                                session: <ReactVideoPlayer 
                                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/command-healing/Command+Healing+-+Day+1.mp4"
                                    title="Command Healing: Session 1"
                                />,
                            }
                        ],
                    }
                ]
            },
            {
                title: 'Command Healing - Session 2',
                image: "https://karinagrant.s3.eu-west-1.amazonaws.com/program/command-healing/command-healing-min.jpg",
                content: [
                    {
                        video: [
                            {
                                title: 'Session 2',
                                session: <ReactVideoPlayer 
                                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/command-healing/Command+Healing+-+Day+2.mp4"
                                    title="Command Healing: Session 2"
                                />,
                            }
                        ],
                    }
                ]
            },
        ],
    }
]
  
  export default fixture
  




// sound-bell.jpg