const fixture = [
    {
        programName: "Quantum-Touch Chakra Balancing",
        featuredImage: "/app/images/quantum-touch/chakra-balancing.jpg",
        tags: ['quantum-touch'],
        type: 'single',
        title: "Quantum-Touch Chakra Balancing",
        duration: "29",
        audioMp3: "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/chakra-balancing.mp3"
    }
]
export default fixture