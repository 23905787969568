import styled, { css } from "styled-components"
import breakpoints from "../breakpoints"
import { baseGridStyles } from "../base"
import { regular9, bold16, bold18, bold24, navFontFamily} from "../typography"
import Modal from "styled-react-modal"
import { StyledButton } from "../button"


export const StyledVideoItem = styled.div`
    position:relative;
    span svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6rem;
        height: 6rem;
        z-index: 2;
        path {
            opacity: 0.8;
        }
    }
`


const StyledVideo = styled.ul`
    ${baseGridStyles}
    list-style: none;
    margin: 0;
    padding: 0;
    ${props => props.type === 'program' && 'margin-bottom: 4.8rem;'}

    @media screen and (min-width: ${breakpoints.md}px) {
        padding: 0;
        grid-row-gap: 3.2rem;
    }
    
`

const StyledVideoCard = styled.li`
    grid-column: span 4;
    position: relative;
    display: inline-block;
    
    cursor: pointer;
    & > div:first-child {
        ${props => props.color && `
            &:after{content:" ";
            background: ${props.color};
            height: 100%;
            width: 100%;
            display: block;
            position: absolute;
            opacity: 0.3;
            z-index: 1;
            top: 0;
        }`}
    }
    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 3;

        .single-audio & {
            grid-column: span 6;
        }
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 4;
        .single-audio & {
            grid-column: 3 / 11;
        }
    }
    img {
        width: 100%;
        vertical-align: middle;
        position: relative;
        
    }

    
`
const StyledVideoTitle = styled.h2`
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8rem 3rem 1.5rem 1.5rem;
    color: #fff;
    margin: 0;
    height: auto;
    background: #000;
    width: 100%;
    background: linear-gradient(0deg, #000 0%, #00111400 100%);
    @media screen and (min-width: ${breakpoints.sm}px) {
        ${bold16}
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        ${bold18}
    }
`

const StyledVideoDuration = styled.span`
    position: absolute;
    top: 1rem;
    left: 1rem;
    background-color: #000;
    color: #fff;
    padding: 0.6rem;
    border-radius: 20px;
    font-size: 0.9rem;
    line-height: 2;
    // width: 4.8rem;
    text-align: center;
    ${regular9}
    z-index: 2;
`


const StyledVideoModal = Modal.styled`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: white;
    opacity: ${(props) => props.opacity};
    transition : all 0.3s ease-in-out;
    overflow: auto;
    padding: 0;
    text-align: left;
    background-image: url( ${(props) => props.backgroundImage});
    min-height: 100vh;
    min-height: -webkit-fill-available;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    

    &:after {
        content: "";
        opacity: 0.3;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: fixed;
        background-color: #000;
        width: 100vw;
        height: 100vh;
    }
    
`

const StyledVideoModalCloseButton = styled(StyledButton)`
  
  
  .single-audio-modal & {
    position: absolute;
    top: 1.4rem;
    right: 2rem;
    min-width: auto;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: block;
    margin-top: 0;
    background-color: transparent;
    z-index: 100;

    @media screen and (min-width: ${breakpoints.sm}px) {
        top: 2.2rem;
        right: 2.8rem;
    }
    @media screen and (min-width: ${breakpoints.md}px) {
      left: calc(100% - 6rem);
    }

    svg {
        position: absolute;
        left: 0.7rem;
        top: 0rem;
        transform: rotate(180deg);
        width: 24px;
        height: 24px;
        path {
            fill: var(--white);   
        } 
        .topBun {
            fill: #fff;
            transform: rotate(45deg);
            transition: all 0.35s ease-in-out;
            transform-origin: 0.1rem 0.4rem;
            }

            .bottomBun {
            fill: #fff;
            transform: rotate(-45deg);
            transition: all 0.35s ease-in-out;
            transform-origin: 0 1rem;
            }
    }
  }
`

const StyledSingleVideoHeader = styled.header`
    ${baseGridStyles}
    z-index: 10;
    width: 100vw;
    height: 20vh;
    position: fixed;
    @media screen and (min-width: ${breakpoints.sm}px) {
        
    }
    @media screen and (min-width: ${breakpoints.md}px) {
        
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        max-width: 100%;
    }
    
    .iconContainer {
        grid-column: 2 / 4;
        width: 45px;
        height: 45px;
        background: #fff;
        border-radius: 50%;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        @media screen and (min-width: ${breakpoints.sm}px) {
            grid-column: span 12;
        }

        @media screen and (min-width: ${breakpoints.md}px) {
            grid-column: span 12;
        }
    }
`

const StyledSingleVideoTitle = styled.h2`
    grid-column: span 4;
    color: #fff;
    z-index: 1;
    margin: 0 auto;
    ${bold24}
    text-align: center;
    @media screen and (min-width: ${breakpoints.sm}px) {
        grid-column: span 6;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 12;
    }
`

const StyledSingleVideoBody = styled.article`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    margin: 0 auto;
    height: 66vh;
    margin-top:20vh;
    padding: 1.6rem;

    @media screen and (min-width: ${breakpoints.sm}px) {
        width: 70%;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
        width: 50%;
        height: 75vh;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        width: 30%;
        margin-bottom: 4.8rem;
    }
`

const StyledSingleVideoBodyWithCopy = styled.article`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    margin: 0 auto;

    @media screen and (min-width: ${breakpoints.sm}px) {
        width: 70%;
    }

    @media screen and (min-width: ${breakpoints.md}px) {
        width: 50%;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
        width: 30%;
        margin-bottom: 4.8rem;
    }
    div.content-wrapper {
        width: 100%;
        height: 73vh;
        overflow: auto;
        z-index: 10;
        padding: 0 1.6rem;
        margin-bottom: 2.4rem;

        &.has-tabs {
            margin-bottom: 7rem;
            height: 70vh;
        }

        .react-player {
            width: 100%;
            @media screen and (min-width: ${breakpoints.sm}px) {
                height: 320px!important;
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }
    p, h2, h3{
        color: var(--white);
        margin-bottom: 1.6rem;;

        &.endpara {
            margin-top: 3.2rem;
        }
    }
    h2.week-label {
        display: inline-block;
        padding: 0.3rem 0.7rem;
        width: auto;
        background: #ece204;
        font-size: 1.2rem;
        color: #000;
        margin-bottom: 0;
        margin-top: 3.2rem;
    }
    &.sleep-audio {
        .accordion .accordion__button {
            color: var(--white);
        }
    }

    video {
        width: inherit;
    }
    .video-audio-tabs {
        .react-tabs {
            padding-top: 0;
            margin-bottom: 8rem;

        }
        .react-tabs__tab-panel {
            color: var(--white);;
        }
        ul {
            position: absolute;
            bottom: 0;    
            left: 0;
            flex-wrap: wrap;
            justify-content: space-around;
            width:100%;
            list-style: none;
            padding: 1.6rem;
            border-top: 0;
            border-bottom: 0;
            margin-bottom: 0;
            ${baseGridStyles}
            z-index: 4;
            li {
                grid-column: span 2;
                color: var(--white);
                background: var(--white);
                /* width: 100%; */
                border-radius: 0.3rem;
                color: var(--grey-mid-darker);
                padding: 0.8rem;
                text-align: center;
                margin: 0;

                @media screen and (min-width: ${breakpoints.sm}px) {
                    &:nth-child(1) {
                        grid-column: 2 / 4;
                    }
                    &:nth-child(2) {
                        grid-column: 4 / 6;
                    }
                }

                @media screen and (min-width: ${breakpoints.md}px) {
                    &:nth-child(1) {
                        grid-column: 4 / 7;
                    }
                    &:nth-child(2) {
                        grid-column: 7 / 10;
                    }
                }

                @media screen and (min-width: ${breakpoints.l}px) {
                    &:nth-child(1) {
                        grid-column: 7 / 9;
                    }
                    &:nth-child(2) {
                        grid-column: 9 / 11;
                    }
                }


                &.react-tabs__tab--selected {
                    background-color: var(--light-purple-mid);
                }
            }
        }
        .audio {
            .rhap_container {
                margin-bottom: 4rem;
            }
        }

        .accordion__button h3{
            margin: 0;
        }
    }
`



export {
    StyledVideo,
    StyledVideoCard,
    StyledVideoTitle,
    StyledVideoDuration,
    StyledVideoModal,
    StyledVideoModalCloseButton,
    StyledSingleVideoHeader,
    StyledSingleVideoTitle,
    StyledSingleVideoBody,
    StyledSingleVideoBodyWithCopy
}