const fixture = [
    {
        programName: "Quantum-Touch Morning Meditation",
        featuredImage: "/app/images/quantum-touch/morning-meditation.jpg",
        tags: ['quantum-touch'],
        type: 'single',
        title: "Morning Meditation",
        duration: "12",
        audioMp3: "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/morning-meditation.mp3"
    }
] 
export default fixture