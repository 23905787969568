import styled from "styled-components"
import breakpoints from "./breakpoints"

const DownloadIcon = styled.button`
  background: none;
  outline: none;
  border: none;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  height: 3.4rem;
  position: absolute;
  right: 4.6rem;
  top: 1.7rem;

  @media screen and (min-width: ${breakpoints.sm}px) {
      right: 6rem;
      top: 4.2rem;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    right: 8.6rem;
    top: 4.2rem;
  }
 
  @media screen and (min-width: ${breakpoints.l}px) {
    position: sticky;
    left: calc(100% - 9rem);
    top: 3rem;
    right: 2rem;
    margin-right: 5rem;
  }
  .cls-1 {
    fill-rule: evenodd;
  }

  svg.delete-local {
    position: relative;
    top: -0.4rem;
    right: 0rem;
    width: 2.6rem;
  }
  svg path {
    fill: ${props => props.isDownloaded ? 'red' : 'var(--green-strong)'};
  }
  svg circle {
    
    &.cirle-filled {
        stroke: var(--green-strong);
    }
  }
  svg {
    height: 100%;
    
  }
`

const DownloadBanner = styled.div`
  background: #00000073;
  width: 100vw;
  color: white;
  position: fixed;
  bottom: 0;
  top: 0;
  z-index: 100;
`
const DownloadMessage = styled.span`
    background: var(--green-strong);
    position: fixed;
    width: 100vw;
    left: 0;
    bottom: 1rem;
    padding: 16px;
    font-weight: 700;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-flow: row wrap;
    background-color: green;
    align-content: flex-start;

    .downloading {
        width: 30px;
        height: 32px;
        margin-right: 1.4rem;
        position: relative;
        overflow: hidden;
        display: inline-block;
      }
      
      .custom-arrow {
        width: 14px;
        height: 10px;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -7px;
        background-color: #fff;
        -webkit-animation-name: downloading;
        -webkit-animation-duration: 1.5s;
        -webkit-animation-iteration-count: infinite;
        animation-name: downloading;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
      }
      .custom-arrow:after {
        content: '';
        position: absolute;
        display: block;
        top: 100%;
        left: -9px;
        border-top: 15px solid #fff;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
      }
      
      @-webkit-keyframes downloading {
        0% {
          top: 0;
          opacity: 1;
        }
        50% {
          top: 110%;
          opacity: 0;
        }
        52% {
          top: -110%;
          opacity: 0;
        }
        100% {
          top: 0;
          opacity: 1;
        }
      }
      @keyframes downloading {
        0% {
          top: 0;
          opacity: 1;
        }
        50% {
          top: 110%;
          opacity: 0;
        }
        52% {
          top: -110%;
          opacity: 0;
        }
        100% {
          top: 0;
          opacity: 1;
        }
    }
`

const DownloadFailedBanner = styled.span`
    background: var(--red-mid);
    display: block;
    padding: 1.6rem;
    color: var(--white);
    font-weight: 700;
    grid-column: span 4;
    position: relative;

    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 12;
    }
    button {
        background: var(--white);
        margin-left: 0;
        color: var(--light-purple-darkest);
        border: 0.1rem solid var(--light-purple-darkest);

        &:hover {
            background: var(--light-purple-darkest);
            color: var(--white);
        }
    }
`

const OfflineWarningBanner = styled.span`
    background: var(--red-mid);
    display: block;
    padding: 1.6rem;
    color: var(--white);
    font-weight: 700;
    grid-column: span 4;
    position: relative;

    @media screen and (min-width: ${breakpoints.md}px) {
        grid-column: span 12;
    }
`

const ErrorBanner = styled.div`
  background: red;
  padding: 16px;
  width: 100vw;
  color: white;
  position: fixed;
  bottom: ${props => props.isDownloading ? '119px' : '63px'};
  z-index: 100;
`

export {
    DownloadIcon,
    DownloadBanner,
    DownloadMessage,
    DownloadFailedBanner,
    ErrorBanner,
    OfflineWarningBanner
}