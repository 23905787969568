import React, { useState } from "react"
import styled from "styled-components"
import { useShoppingCart } from 'use-shopping-cart'
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import { StyledModal,StyledModalHeader } from "../../styledComponents/modal"
import Icon from "../../components/icon"
import KarinaGrantLogo from "../logo"
import lunchtimeReset from "../../fixtures/mindfulness-collection-lunchtime-reset"
import { StyledAudio } from "../../styledComponents/app/audioCard"
import AudioModal from "../../components/App/AudioModal"

import {
  StyledProductPrimaryCard,
  ProductHeader,
  ProductExcerpt,
  ProductFigure,
  ProductFigureCaption
} from "./StyledPrimaryProductCard"

import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser"
import { StyledButton, StyledAddToCartButton, StyledRegisterNowButton, StyledCloseModalButton, StyledLinkButton } from "../../styledComponents/button"

import { navigate } from "@reach/router";
function FancyModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {    
    setOpacity(0);
    setIsOpen(!isOpen);
  }
  
  function closeModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }



  const collection = lunchtimeReset 

  return (
      <>
      <StyledButton className="listen-now" onClick={toggleModal}>Listen</StyledButton>
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        className="audio-list-modal"
      >
        <StyledModalHeader>
          <div>
            <KarinaGrantLogo />
            <StyledCloseModalButton className="close-audio" onClick={closeModal}>
              <Icon name="cross" />
            </StyledCloseModalButton>
          </div>
        </StyledModalHeader>
        <StyledAudio className="single-audio">
          <AudioModal audio={collection[0]}/>     
        </StyledAudio>
        </StyledModal>
      </>
  )
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`;

const ProductCardPurchase = props => {
    const { 
        title
      } = props
      const { addItem, checkoutSingleItem } = useShoppingCart()
      const image = {
        imageData: props.data.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
        alt: props.data.featuredImage?.node?.alt || ``,
        description: props.data.featuredImage?.node?.description || "",
      }

      const goToProduct = (url) => {
        navigate(url)
      }
    return (
        <StyledProductPrimaryCard
      itemScope
      itemType={props.data.productTag === 'book' ? "" : "http://schema.org/Product"}
    >
      {image && image.imageData && (
          <ProductFigure onClick={() => {goToProduct(props.data.uri)}}>
            <GatsbyImage
              image={image.imageData}
              alt={image.alt}
              width="100%"
              padding-bottom="0" />
            <ProductFigureCaption>{image.description}</ProductFigureCaption>
          </ProductFigure>
        )}
        <ProductHeader onClick={() => {goToProduct(props.data.uri)}} itemProp="name">{props.data.title}</ProductHeader>
        <ProductExcerpt>{parse(props.data.excerpt)}
        {props.data.pricesale || props.data.pricefull ?
            <StyledLinkButton className="read-more" to={props.data.uri}>Read more...</StyledLinkButton>
        : null
        }
        </ProductExcerpt>
        {(process.env.GATSBY_SHOW_SALE === 'true' && props.data.productTag != 'book') ? 
          <div className="product-price sale" itemProp="offers" itemScope itemType="https://schema.org/AggregateOffer">
            30% off <span className="strikethrough">{props.data.currency}{props.data.pricefull}</span> <span itemProp="lowPrice">{props.data.currency}{props.data.pricesale}</span>
            <span itemProp="priceCurrency" className="hidden">{props.data.currency === '$' ? 'USD' : 'GBP'}</span>
          </div>
      : props.data.pricefull ? 
          <div className="product-price" itemProp="offers" itemScope itemType="https://schema.org/AggregateOffer">
            <span itemProp="lowPrice">{props.data.currency}{props.data.pricefull}</span>
            <span itemProp="priceCurrency" className="hidden">{props.data.currency === '$' ? 'USD' : 'GBP'}</span>
          </div>

        : props.data.productTag === 'book' ? 
          <div className="product-price">
            <span>BOOK</span>
          </div>
          :
          <div className="product-price" itemProp="offers" itemScope itemType="https://schema.org/AggregateOffer">
            <span>FREE</span>
            <span itemProp="lowPrice" className="hidden">0</span>
            <span itemProp="priceCurrency" className="hidden">{props.data.currency === '$' ? 'USD' : 'GBP'}</span>
          </div>
          }
        
        
        
      {props.data.pricesale && !props.data.hidesale ? 
      <></>
       : props.data.pricefull ? 
       <div className="sales-buttons">
      </div>

      : props.data.productTag === 'book' ? 
        <>
          <StyledLinkButton target="_blank" href={props.data.amazonLink} className="amazon">Buy on Amazon</StyledLinkButton>
          </>
        :
          <ModalProvider backgroundComponent={FadingBackground}>
            <FancyModalButton id={props.data.id} title={props.data.title}/>
          </ModalProvider>
      }
      
    </StyledProductPrimaryCard>

    )
}
export default ProductCardPurchase