import React from "react"
import {
    ReactVideoPlayer,
    ReactAudioPlayer
 } from "../components/reactPlayer"
const fixture = [
    {
        programName: "Spoon Therapy",
        type: 'spoon-therapy',
        featuredImage: "/images/spoon-therapy-min.jpg",
        audioFiles: [            
            {
                title: 'Spoon therapy - Session 1',
                image: "https://karinagrant.s3.eu-west-1.amazonaws.com/program/spoon-therapy/spoon-therapy-min.jpg",
                content: [
                    {
                        video: [
                            {
                                title: 'Session 1',
                                session: <ReactVideoPlayer 
                                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/spoon-therapy/Spoon+Therapy+-+Day+1.mp4"
                                    title="Spoon therapy: Session 1"
                                />,
                            }
                        ],
                    }
                ]
            },
            {
                title: 'Spoon therapy - Session 2',
                image: "https://karinagrant.s3.eu-west-1.amazonaws.com/program/spoon-therapy/spoon-therapy-min.jpg",
                content: [
                    {
                        video: [
                            {
                                title: 'Session 2',
                                session: <ReactVideoPlayer 
                                    videoURL="https://karinagrant.s3.eu-west-1.amazonaws.com/program/spoon-therapy/Spoon+Therapy+-+Day+2.mp4"
                                    title="Spoon therapy: Session 2"
                                />,
                            }
                        ],
                    }
                ]
            },
        ],
    }
]
  
  export default fixture
  




// sound-bell.jpg