import React, { useLayoutEffect, useState } from "react"
import {
  Section,
} from "../styledComponents/section"
import permissionToReset from "../fixtures/permission-to-reset-nervous-system"
import AudioResetCard from "./App/audioResetCard"
import {
  StyledAudio,
} from "../styledComponents/app/audioCard"
import ProgramInfoList from "./programInfoList"
const ResetProgram = () => {
  const sleep = permissionToReset

  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  const [isRelaxCookieSet, setIsRelaxCookieSet] = useState(true)
  const checkIfRelaxCookieExists = () => {
    document.cookie
      .split(";")
      .some(item => item.trim().startsWith("reset-program-info="))
      ? setIsRelaxCookieSet(true)
      : setIsRelaxCookieSet(false)
  }

  const createRelaxCookie = (value) => {
    document.cookie = value+`=true; path=/; host=${
      process.env.GATSBY_DOMAIN_NAME
      }; ${process.env.GATSBY_SECURE_COOKIE ? "secure;" : ""}`
    setIsRelaxCookieSet(true)
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      checkIfRelaxCookieExists()
      return
    }
  }, [])

    return (
        <>
          <Section>
            <StyledAudio type="program">
              {
                sleep[0].audioFiles.map(audio => {
                  return (
                    <AudioResetCard 
                      key={audio.title + `-1`}
                      title={audio.title}
                      image={audio.image}
                      content={audio.content}
                    />     
                  )
                })
              }
            </StyledAudio>
            <ProgramInfoList />
          </Section>
        </>
    )
}

export default ResetProgram