import React, { useLayoutEffect, useState } from "react"
import { Section } from "../styledComponents/section"
import permissionToSleep from "../fixtures/permission-to-sleep"
import AudioSleepCard from "../components/App/audioSleepCard"
import { StyledAudio } from "../styledComponents/app/audioCard"
import ProgramInfoList from "./programInfoList"
import SleepNotice from "../components/sleepNotice"
const SleepProgram = ({ collection }) => {
  const sleep = collection

  const [isOpen, setIsOpen] = useState(false)
  const [opacity, setOpacity] = useState(0)

  const [isSleepCookieSet, setIsSleepCookieSet] = useState(true)
  const checkIfSleepCookieExists = () => {
    document.cookie
      .split(";")
      .some(item => item.trim().startsWith("permission-reset-sleep-info="))
      ? setIsSleepCookieSet(true)
      : setIsSleepCookieSet(false)
  }

  const createSleepCookie = value => {
    document.cookie =
      value +
      `=true; path=/; host=${process.env.GATSBY_DOMAIN_NAME}; ${
        process.env.GATSBY_SECURE_COOKIE ? "secure;" : ""
      }`
    setIsSleepCookieSet(true)
  }

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      checkIfSleepCookieExists()
      return
    }
  }, [])

  if (!collection || !collection[0].audioFiles) return

  return (
    <>
      <Section>
        <StyledAudio type="program">
          {sleep[0].audioFiles.map(audio => {
            return (
              <AudioSleepCard
                key={audio.title + `-1`}
                title={audio.title}
                image={audio.image}
                content={audio.content}
                // audioMp3={audio.audioMp3}
                // duration={audio.duration}
              />
            )
          })}
        </StyledAudio>
        <ProgramInfoList />
        {!isSleepCookieSet && (
          <SleepNotice
            isSleepCookieSet={isSleepCookieSet}
            createSleepCookie={createSleepCookie}
          />
        )}
      </Section>
    </>
  )
}

export default SleepProgram
