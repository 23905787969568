const fixture = [
    {
      programName: "Permission Bonus Tracks",
      featuredImage: "/app/images/chakra-balancing-collection/chakra-balancing-collection.jpg",
      audioFiles: [
        {
            title: "Gratitude",
            color: "green",
            image: "/app/images/permission-bonus/bringing-in-positive-daily-habits.jpg",
            audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/permissioning/bonus-topics/gratitude.m4a"
        },
        {
            title: "Eat Healthily",
            color: "red",
            image: "/app/images/permission-bonus/eating-healthy.jpg",
            audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/permissioning/bonus-topics/eat-healthily.m4a"
        },
        {
            title: "Mind, Body & Spirit",
            color: "orange",
            image: "/app/images/permission-bonus/qt-morning-meditation.jpg",
            audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/permissioning/bonus-topics/mind-body-spirit.m4a"
        },
        {
            title: "Moving Energy",
            color: "yellow",
            image: "/app/images/permission-bonus/transformation-gratitiude.jpg",
            audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/permissioning/bonus-topics/moving-energy-23092020.m4a"
        },
        {
            title: "Self Confidence",
            color: "green",
            image: "/app/images/permission-bonus/self-confidence.jpg",
            audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/permissioning/bonus-topics/self-confidence-24082020.m4a"
        },
        {
            title: "Healthy Body",
            color: "blue",
            image: "/app/images/permission-bonus/body-balance.jpg",
            audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/permissioning/bonus-topics/healthy-body-21072020.m4a"
        },
        {
            title: "Self Love",
            image: "/app/images/permission-bonus/heart-chakra-meditation.jpg",
            audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/permissioning/bonus-topics/resilience-17062020.m4a"
        },
        {
            title: "PTSD",
            image: "/app/images/permission-bonus/ptsd.jpg",
            audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/permissioning/bonus-topics/ptsd-20052020.m4a"
        },
        {
            title: "Manifesting",
            image: "/app/images/permission-bonus/manifesting.jpg",
            audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/permissioning/bonus-topics/manifesting-22042020.m4a"
        },
        {
            title: "Harmony in relationships",
            image: "/app/images/permission-bonus/harmony-in-relationships.jpg",
            audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/permissioning/bonus-topics/harmony-in-relationships-11032020.m4a"
        },
        {
            title: "Stress, Depression & Anxiety",
            image: "/app/images/permission-bonus/stress.jpg",
            audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/permissioning/bonus-topics/stress-depression-anxiety-12022020.m4a"
        },
        {
            title: "Success & using your gifts",
            image: "/app/images/permission-bonus/success.jpg",
            audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/permissioning/bonus-topics/success-22012020.m4a"
        },
      ],
    }
  ]
  
  export default fixture
  




// sound-bell.jpg