import styled from "styled-components"
import breakpoints from "./breakpoints"

const StyledProgramInfoList = styled.ul`
    position: fixed;
    left: 0%;
    bottom: 0;
    z-index: 1;
    width: 100%;
    list-style: none;
    display: inline;
    background-color: var(--light-purple-darkest);
    display: flex;
    flex-direction: row;
    width: 100vw;
    margin: 0;
    padding: 0!important;
    box-shadow: 0px -8px 20px 0px rgb(173 173 173 / 75%);
    li {
        display: inline;
        width: 50vw;
        text-align: center;
        font-size: 1.8rem;
        font-weight: 400;
        width: 100%;
        flex-direction: column;
        color: var(--white);
        padding: 0.8rem 0;
        cursor: pointer;
        @media screen and (min-width: ${breakpoints.md}px) {
            padding: 1.6rem 0;
        }
        svg {
            width: 25px;
            position: relative;
            top: 4px;
            margin-right: 0.8rem;
            path {
                fill: var(--white);
            }
        }
    }
`

export {
    StyledProgramInfoList
}
