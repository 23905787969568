import React from "react"
import { Video } from 'reactjs-media'
import ReactPlayer from 'react-player/lazy'
import {
    ReactVideoPlayer,
    ReactAudioPlayer
 } from "../components/reactPlayer"

const fixture = [
    {
        programName: "Reset Your Nervous System",
        featuredImage: "",
        type: 'reset-program',
        featuredImage: "/app/images/quantum-touch/morning-meditation.jpg",
        audioFiles: [
            {
                title: "Session 1",
                image: "/app/images/permission-to-sleep/quick-start.jpg",
                content: [
                    {
                        audio: [
                            {
                                title: '<h3>Long version</h3>',
                                audioMp3: <ReactAudioPlayer 
                                    audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/hand-to-heart/combined.mp3' 
                                    title='Reset Your Nervous System : Session 1 : Long'
                                />,
                            },
                        ],
                        video: [
                            {
                                title: '<h2>Hand to heart</h2>',
                                handToHeart: <ReactVideoPlayer 
                                videoURL="https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/hand-to-heart/h2h-caricature.mp4"
                                title="Reset Nervous System : Hand to heart"
                            />,
                            }
                        ],
                    }
                ]
            },
            {
                title: "Session 2",
                image: "/app/images/permission-to-sleep/hand-heart.jpg",
                showTabs: false,
                content: [
                    {
                        audio: [
                            {
                                title: '<h3>Long version</h3>',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/hand-to-heart/combined.mp3'title='Reset Your Nervous System : Session 2 : Long'/>,
                            },
                        ],
                        video: [
                            {
                                title: '<h2>Hand to heart</h2>',
                                handToHeart: <ReactPlayer 
                                width='100%'
                                className='react-player'
                                url='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/hand-to-heart/h2h-caricature.mp4' 
                                controls={false}
                                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                onContextMenu={e => e.preventDefault()}
                                light='/app/images/permission-to-sleep/hand2heart-poster.png'
                              />,
                            }
                        ],
                    }
                ]
            },
            {
                title: "Session 3",
                image: "/app/images/permission-to-sleep/sleep-instructions.jpg",
                showTabs: "true",
                content: [
                    {
                        audio: [
                            {
                                title: '<h3>An example sequence</h3>',
                                audioMp3: <ReactAudioPlayer audioURL='https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/an-example-sequence.mp3' title='Reset Your Nervous System : Session 3 : An example sequence'/>,
                            },
                        ],
                        video: [
                            {
                                title: '<h3>An example Permission</h3>',
                                handToHeart: <Video src="https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/videos/example-permission-720.mp4" />,
                            },
                        ],
                    }
                ]
            },
        ],
    }
]
  
  export default fixture
  




// sound-bell.jpg