import React, { useEffect, useState } from "react"
import styled from "styled-components"
import parse from "html-react-parser"
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { ModalProvider, BaseModalBackground } from "styled-react-modal"

import 'react-h5-audio-player/lib/styles.css'
import Icon from "../icon"
import {
    StyledAudioTitle,
    StyledAudioDuration,
} from "../../styledComponents/app/audioCard"
import { analyticClickEvent } from "../googleAnalytics"
import localforage from "localforage"
import { getBlobURL } from '../../functions/utils';
import { 
  StyledSingleVideoBodyWithCopy, 
  StyledSingleVideoHeader, 
  StyledSingleVideoTitle, 
  StyledVideoCard, 
  StyledVideoModal, 
  StyledVideoModalCloseButton 
} from "../../styledComponents/app/videoCard"

function VideoModal(data) {
    const identity = useIdentityContext()
    const [isOpen, setIsOpen] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const [localUrl, setLocalUrl] = useState();

    useEffect(() => {
      async function getBackgroundUrl() {
        const localBackgroundImage = await localforage.getItem('sleepStarsBackground')
        if (localBackgroundImage) setLocalUrl(getBlobURL(localBackgroundImage))
      }

      getBackgroundUrl();
    }, []);

    function toggleModal(e) {
      setOpacity(0);
      setIsOpen(!isOpen);
      let action
      if(identity.user){
        action = 'UserID: ' + identity.user.id + ' Opens'
      }
      else {
        action = 'UserID: NOT LOGGED IN Opens'
      }
      analyticClickEvent(action,'Engagement', data.audio.title, '')
    }

    function toggleModalClose(e) {
      setOpacity(0);
      setIsOpen(!isOpen);
      let action
      if(identity.user){
        action = 'UserID: ' + identity.user.id + ' Closes'
      }
      else {
        action = 'UserID: NOT LOGGED IN Closes'
      }
      analyticClickEvent(action,'Engagement', data.audio.title, '')
    }
  
    function afterOpen() {
      setTimeout(() => {
        setOpacity(1);
      }, 100);
    }
  
    function beforeClose() {
      return new Promise((resolve) => {
        setOpacity(0);
        setTimeout(resolve, 300);
      });
    }
    const audio = data.audio
    return (
      <>
        <div onClick={toggleModal}>
            <StyledAudioTitle>{audio.title}</StyledAudioTitle>
            <img src={audio.image} />
            {audio.duration && <StyledAudioDuration>{audio.duration} min</StyledAudioDuration>}
        </div>
            <StyledVideoModal
                isOpen={isOpen}
                afterOpen={afterOpen}
                beforeClose={beforeClose}
                onBackgroundClick={toggleModal}
                onEscapeKeydown={toggleModal}
                opacity={opacity}
                backgroundProps={{ opacity }}
                backgroundImage={audio.image}
                localImage={localUrl}
                className="single-audio-modal"
                program="sleep"
            >

                <StyledSingleVideoHeader>
                    <StyledVideoModalCloseButton onClick={toggleModalClose}>
                        <Icon name="cross" />
                    </StyledVideoModalCloseButton>
                    <div className="iconContainer">
                        <Icon name="karinagrant" />
                    </div>
                    <StyledSingleVideoTitle>{audio.title}</StyledSingleVideoTitle>
                </StyledSingleVideoHeader>
                <StyledSingleVideoBodyWithCopy className="sleep-audio">
                    
                    
                    {audio.content && 
                      audio.content.map(info => {
                      return (
                        <div className='content-wrapper'>
                          {info.video.map(video => {
                            return (
                              <>
                                {video.session}
                              </>
                            )
                          })}
                        </div>
                      )
                    })
                    }

                </StyledSingleVideoBodyWithCopy>
            </StyledVideoModal>
        
      </>
    )
  }
  
const FadingBackground = styled(BaseModalBackground)`
    opacity: ${(props) => props.opacity};
    transition: all 0.3s ease-in-out;
`

const VideoCommandHealingCard = props => {
    return (        
        <StyledVideoCard>
            <ModalProvider backgroundComponent={FadingBackground}>
                <VideoModal audio={props} />
            </ModalProvider>
        </StyledVideoCard>
    )
}

export default VideoCommandHealingCard
