import React from "react"
import {
  SleepNoticeContainer,
  SleepCookieButton,
} from "../styledComponents/sleepNotice"

const SleepNotice = ({ isSleepCookieSet, createSleepCookie, hideSleepNotice }) => {
  return (
    <>
      <SleepNoticeContainer isSleepCookieSet={isSleepCookieSet}>
        <div>
          <h2>Sleep Notice</h2>
          <p>Please note that when listening to this program, you must be in bed, lying down and ready to have a deep, uninterupted, refreshing sleep.</p>
          <SleepCookieButton onClick={() => createSleepCookie('permission-reset-sleep-info')}>
            I understand
          </SleepCookieButton>
          </div>
      </SleepNoticeContainer>
    </>
  )
}

export default SleepNotice
