import React, { useEffect, useState } from "react"
import styled from "styled-components"
import parse from "html-react-parser"
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-h5-audio-player/lib/styles.css'
import Icon from "../icon"
import {
    StyledAudioCard,
    StyledAudioTitle,
    StyledAudioDuration,
    StyledAudioSleepModal,
    StyledAudioModalCloseButton,
    StyledSingleAudioHeader,
    StyledSingleAudioTitle,
    StyledSingleAudioBodyWithCopy
} from "../../styledComponents/app/audioCard"
import localforage from "localforage"
import { getBlobURL } from '../../functions/utils';

function AudioModal(data) {
    const [isOpen, setIsOpen] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const [localUrl, setLocalUrl] = useState();

    useEffect(() => {
      async function getBackgroundUrl() {
        const localBackgroundImage = await localforage.getItem('sleepStarsBackground')
        if (localBackgroundImage) setLocalUrl(getBlobURL(localBackgroundImage))
      }

      getBackgroundUrl();
    }, []);
  
    function toggleModal(e) {
      setOpacity(0);
      setIsOpen(!isOpen);
    }
  
    function afterOpen() {
      setTimeout(() => {
        setOpacity(1);
      }, 100);
    }
  
    function beforeClose() {
      return new Promise((resolve) => {
        setOpacity(0);
        setTimeout(resolve, 300);
      });
    }
    const audio = data.audio
    return (
      <>
        <div onClick={toggleModal}>
            <StyledAudioTitle>{audio.title}</StyledAudioTitle>
            <img src={audio.image} />
            {audio.duration && <StyledAudioDuration>{audio.duration} min</StyledAudioDuration>}
        </div>
            <StyledAudioSleepModal
                isOpen={isOpen}
                afterOpen={afterOpen}
                beforeClose={beforeClose}
                onBackgroundClick={toggleModal}
                onEscapeKeydown={toggleModal}
                opacity={opacity}
                backgroundProps={{ opacity }}
                backgroundImage={audio.image}
                localImage={localUrl}
                className="single-audio-modal"
                program="sleep"
            >
                
                <StyledSingleAudioHeader>
                    <StyledAudioModalCloseButton onClick={toggleModal}>
                        <Icon name="cross" />
                    </StyledAudioModalCloseButton>
                    <div className="iconContainer">
                        <Icon name="karinagrant" />
                    </div>
                    <StyledSingleAudioTitle>{audio.title}</StyledSingleAudioTitle>
                </StyledSingleAudioHeader>
                <StyledSingleAudioBodyWithCopy className="sleep-audio">
                    <div className="content-wrapper">
                    
                    {audio.content && 
                      audio.content.map(info => {
                      return (
                        <>

                        {/* Hand 2 heart */}
                        {info.audio && info.video && audio.title.includes('Hand to heart') &&
                        <>
                          <h3>Watch the Hand to heart</h3>
                          {info.video.map(video => {
                            return (
                              <>
                                <video width='320' height='240' controls><source src={video?.handToHeart?.props?.videoURL || 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/hand-to-heart/h2h-caricature.mp4'} type='video/mp4' /></video>
                              </>
                            )
                          })}
                          <h3>​or listen to the Hand to heart Audio</h3>
                          {info.audio &&   
                            <Accordion>
                              {info.audio.map(audios => {
                                return (
                                  <>
                                  <AccordionItem>
                                    <AccordionItemHeading>
                                      <AccordionItemButton className="accordion__button audio-module-item">
                                      {parse(audios.title)}
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      {audios.audioMp3}
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                  </>
                                )
                              })}
                              </Accordion>
                            }
                        </>
                        }

                        {/* Sleep Instructions */}
                        {info.audio && audio.title.includes('Reset Anxiety Introduction') &&
                        <>
                          
                            {info.audio &&   
                              <Accordion preExpanded={['Introduction']}>
                                {info.audio.map(audios => {
                                  return (
                                    <>
                                    <AccordionItem
                                      key={audios.title}
                                      uuid={audios.title}
                                    >
                                      <AccordionItemHeading>
                                        <AccordionItemButton className="accordion__button audio-module-item">
                                        {parse(audios.title)}
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        {audios.audioMp3}
                                      </AccordionItemPanel>
                                    </AccordionItem>
                                    </>
                                  )
                                })}
                              </Accordion>
                            }
                        </>
                        }

                        {/* Permissions for reporgramming the brain */}
                        {info.audio && audio.title.includes('Permissions for Anxiety') &&
                        <>
                          {info.audio &&   
                            <Accordion preExpanded={['Being present in my body and grounded']}>
                              {info.audio.map(audios => {
                                return (
                                  <>
                                  <AccordionItem
                                    key={audios.title}
                                    uuid={audios.title}
                                  >
                                    <AccordionItemHeading>
                                      <AccordionItemButton className="accordion__button audio-module-item">
                                      {parse(audios.title)}
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      {audios.audioMp3}
                                    </AccordionItemPanel>
                                  </AccordionItem>
                                  </>
                                )
                              })}
                              </Accordion>
                            }
                        </>
                      }
                        </>
                      )
                    })
                    }

                    </div>
                </StyledSingleAudioBodyWithCopy>
            </StyledAudioSleepModal>
        
      </>
    )
  }
  
const FadingBackground = styled(BaseModalBackground)`
    opacity: ${(props) => props.opacity};
    transition: all 0.3s ease-in-out;
`

const AudioRelaxCard = props => {
  
    return (        
        <StyledAudioCard>
            <ModalProvider backgroundComponent={FadingBackground}>
                <AudioModal audio={props} />
            </ModalProvider>
        </StyledAudioCard>
    )
}

export default AudioRelaxCard
