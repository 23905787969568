const fixture = [
    {
      programName: "Permission to Reset The Nervous System",
      featuredImage: "/app/images/chakra-balancing-collection/chakra-balancing-collection.jpg",
      audioFiles: [
        {
            title: "Session 1",
            duration: "2 hours 58",
            image: "/images/permissioning-buttefly.jpg",
            audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/program/permission-to-reset-nervous-system/session-1.mp3"
        },
        {
            title: "Session 2",
            duration: "3 hours 32",
            image: "/images/permissioning-buttefly.jpg",
            audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/program/permission-to-reset-nervous-system/session-2-audio.m4a"
        },
        {
            title: "Session 3",
            duration: "3 hours 17",
            image: "/images/permissioning-buttefly.jpg",
            audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/program/permission-to-reset-nervous-system/session-3.mp3"
        },
      ],
    }
  ]
  
  export default fixture
  




// sound-bell.jpg