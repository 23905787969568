import styled from "styled-components"
import breakpoints from "../../styledComponents/breakpoints"
import { baseGridStyles, sectionHeaderFontIncrease } from "../../styledComponents/base";

const StyledProductHeader = styled.header`
  ${baseGridStyles}
  text-align: left;
  margin-top: 1.6rem;
  @media screen and (min-width: ${breakpoints.sm}px) {
    margin-top: 2.4rem;
    padding-top: 2.4rem;
    padding-bottom: 0;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    margin-top: 0;
    padding-top: 3.2rem;
  }
  h1 {
    grid-column: span 4;
    @media screen and (min-width: ${breakpoints.md}px) {
      grid-column: span 12;
    }
  }
  h2 {
    ${sectionHeaderFontIncrease}
    grid-column: span 4;
    @media screen and (min-width: ${breakpoints.sm}px) {
      grid-column: 1/5;
      margin-left: 0;
    }
    @media screen and (min-width: ${breakpoints.l}px) {
      grid-column: 1/7;
    }
  }
  div {
    grid-column: span 4;
    justify-content: center;
    /* margin-top: -1.4rem; */
    @media screen and (min-width: ${breakpoints.sm}px) {
      grid-column: 5/7;
      align-items: center;
      margin-top: 0;
    }
    @media screen and (min-width: ${breakpoints.sm}px) {
      grid-column: 11/13;
      margin-right: 0;
    }
  }
`

const StyledProductListHeader = styled.h2`
  ${props => props.hidden && 'position:absolute; top: -5000px; left: -5000px'};
  grid-column: span 4;
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: 1 / 8;
  }
  @media screen and (min-width: ${breakpoints.l}px) {
    grid-column: 1 / 13;
  }
`

const StyledProductList = styled.ol`
  width: 100%;
  margin: 0;
  list-style: none;
  ${baseGridStyles}
  padding: 0;

  @media screen and (min-width: ${breakpoints.sm}px) {
    margin: 0 auto;
    padding: 0 0;
    grid-column: span 6;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 0 auto;
    padding: 1.6rem 0;
    grid-column: span 12;
  }

  @media screen and (min-width: ${breakpoints.l}px) {
    /* padding: ${props => props.padding ? '0' : '0'}; */
  }

  a,
  a:hover,
  a:focus {
    border-bottom: 0;
  }

`


const StyledProductListItem = styled.li`
  grid-column: span 4;
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: span 3;
    padding-bottom: 1.6rem;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    /* grid-column: ${props => props.type === 'gifts' ? 'span 3' : 'span 4'}; */
    grid-column: ${props => 
      props.type === 'gifts' ? 'span 3' 
    : props.type === 'permissioning' ? 'span 4' 
    : 'span 4'};
  }

  a:hover h1,
  a:hover h2,
  a:hover h3,
  a:hover h4 {
    color: var(--light-green-dark);
  }

`

export {
  
  StyledProductHeader,
  StyledProductListHeader,
  StyledProductList,
  StyledProductListItem
}
