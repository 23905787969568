const programmes = {
    // Permission to reset sleep *
    cG9zdDo3NzY5: {
        audio: {
            week1Audio: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/conditioning-relaxation-combined-tracks/sh2h-cr-faster.mp3',
            week2Audio: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/conditioning-relaxation-combined-tracks/sh2h-safe-place-conditioning-relaxation-faster-speed.mp3',
            week3Audio: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/conditioning-relaxation-combined-tracks/sh2h-safe-place-counting-slow.mp3',
            handToHeartLong: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/hand-to-heart/combined.mp3',
            handToHeartShort: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/short-hand-to-heart/combined.mp3',
            exampleSequence: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/an-example-sequence.mp3',
            understandingInsomnia: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/understanding-insomnia.mp3',
            whatIsPermissioning: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/what-is-permissioning-and-how-does-it-work.mp3',
            howDoesTheProgramWork: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/so-how-does-the-progrm-work.mp3',
            specificSleepIssues: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/specific-sleep-issues.mp3',
            startWithHandToHeart: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/do-you-always-start-with-hand-to-heart.mp3',
            customizingSleepPlan: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/customizing-your-sleep-plan-and-sleep-tips.mp3',
            ifHandGetsTired: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/if-your-hand-gets-tired.mp3',
            followingAlong: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/following-along.mp3',
            whatPermissioningMeans: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/what-does-permission-actually-mean-in-this-context.mp3',
            doesItAlwaysWork: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/does-it-always-work.mp3',
            mindCantFocus: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/what-if-my-mind-cant-focus.mp3',
            disclaimer: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/disclaimer.mp3',
            aversionBed: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/an-aversion-of-going-to-bed/combined.mp3',
            anxietyFallAsleep: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/anxiety-of-not-being-able-to-fall-asleep/combined.mp3',
            breathEasilySleep: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/breathing-easily-during-sleep/combined.mp3',
            deservingRestedBody: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/feeling-deserving-of-a-rested-body/combined.mp3',
            positiveThoughtsBefore: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/positive-thoughts-before-bed/combined.mp3',
            kindToBody: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/being-kind-to-your-body-in-your-regular-habits/combined.mp3',
            relaxingJaw: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/relaxing-the-jaw/combined.mp3',
            healPastTrauma: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/healing-past-trauma-of-not-being-able-to-sleep/combined.mp3',
            stayAsleep: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/go-to-sleep-stay-asleep/combined.mp3',
            sweetDreams: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/sweet-dreams/combined.mp3',
            feelingSafeAsleep: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/feeling-safe-while-asleep/combined.mp3',
            lettingGoSleep: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/permissions/letting-go-into-sleep/combined.mp3'
        },
        video: {
            handToHeart: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/hand-to-heart/h2h-caricature.mp4',
            examplePermission: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/videos/example-permission-720.mp4',
            switchingHands: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/videos/switch-hands-720.mp4',
            journeyHandToHeart: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/videos/how-long-journey-h2h-720.mp4',
            stayingPresentSleep: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/videos/staying-present-with-permissions-720.mp4',
            whatPermissionMean: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/videos/what-does-permission-mean-720.mp4',
            whatCombinationTracks: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/videos/combination-tracks-720.mp4',
            whenBestPractice: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/sleep/instructions/videos/when-to-use-techniques-720.mp4',
        },
    },
    // Permission to reset anxiety * 
    cG9zdDo3MjM0: {
        audio: {
            introductionToAnxiety: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/instructions/introduction-for-anxiety.mp3',
            permissioningHelpAnxiety: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/instructions/how-does-pat-help-with-anxiety.mp3',
            handToHeartLong: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/hand-to-heart/combined.mp3',
            handToHeartShort: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/short-hand-to-heart/combined.mp3',
            presentAndGrounded: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/being-present-in-my-body-and-grounded/combined.mp3',
            feelingSupported: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/feeling-supported/combined.mp3',
            balanceHealthyMindset: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/balance-and-a-healthy-mindset/combined.mp3',
            confidenceDecisionMaking: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/confidence-in-decision-making/combined.mp3',
            physicalEnergy: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/having-more-physical-energy/combined.mp3',
            lettingGoWorries: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/letting-go-of-worries-quickly/combined.mp3',
            safeToRelax: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/feeling-safe-to-relax/combined.mp3',
            increasedConcentration: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/increased-concentration/combined.mp3',
            overcomeOverthinking: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/overcoming-overthinking/combined.mp3',
            positiveBehaviours: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/positive-behaviours/combined.mp3',
            fightFlight: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/coming-out-of-fight-or-flight/combined.mp3',
            physicalAnxiety: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/calming-physical-anxiety-symptoms/combined.mp3',
        },
        video: {
            handToHeart: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/hand-to-heart/h2h-caricature.mp4',
        },
    },
    // Permission bonus
    cG9zdDo3OTI5: {
        audio: {},
        video: {},
    },
    // Permission reset nervous
    cG9zdDo3ODE2: {
        audio: {},
        video: {},
    },
    // Permission reset nervous session one online
    cG9zdDo5MDkz: {
        audio: {},
        video: {},
    },
     // Command Healing
     cG9zdDo4FDkz: {
        audio: {},
        video: {},
    },
    // Essential mindfulness collection *
    cG9zdDo2OTE0: {
        audio: {
            lunchtimeReset: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/lunchtime-reset.mp3',
            findingFeet: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/finding-your-feet.mp3',
            mindfulBodyScan: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/mindful-body-scan.mp3',
            anxietyInnerPeace: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/from-anxiety-to-inner-peace.mp3',
            dailyIntention: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/morning-daily-intention.mp3',
            easySleep: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/easy-sleep.mp3',
            silentBell5: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/silent-bell-5-minute.mp3',
            mindfulBreathing: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/awareness-of-the-breath.mp3',
            cultivatingMindfulness: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/cultivating-mindfulness.mp3',
            compassion: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/compassion-for-opening-the-heart.mp3',
            silentBell15: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/silent-bell-15-minute.mp3',
            beingBody: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/being-in-my-body.mp3',
            gratitude: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/gratitude.mp3',
            activatingSenses: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/activating-your-senses.mp3',
            compassionMeYou: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/compassion-for-me-and-you.mp3',
            silentBell30: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/silent-bell-30-minute.mp3'
        },
        video: {},
    },
    // Chakra balancing *
    cG9zdDo2OTcx: {
        audio: {
            intro: 'https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-intro.mp3',
            root: 'https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-1.mp3',
            sacral: 'https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-2.mp3',
            solarPlexus: 'https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-3.mp3',
            heart: 'https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-4.mp3',
            throat: 'https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-5.mp3',
            thirdEye: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-6.mp3',
            crown: 'https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-7.mp3',
            balancingAll: 'https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-balancing.mp3'
        },
        video: {
        },
    },
    // QT Morning med *
    cG9zdDo2MzU4: {
        audio: {
            morningMeditation: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/morning-meditation.mp3',
        },
        video: {},
    },
    // QT Brain healing *
    cG9zdDo2MzU0: {
        audio: {
            openingHeart: 'https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bh-into-the-heart-space.mp3',
            healingTemporalLobes: 'https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bh-temporal-lobes.mp3',
            healingGlands: 'https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bh-pituitary-and-pineal-glands.mp3',
            healingCallosum: 'https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bh-corpus-callosum.mp3',
            healingAmygdala: 'https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bh-amygdala-hippocampus-hypothalamus.mp3',
            pleasureCenter: 'https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bh-reward-circuit.mp3',
            bathingBrain: 'https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bh-brain-blast.mp3',
        },
        video: {},
    },
    // QT Balancing structure *
    cG9zdDo2MzQ4: {
        audio: {
            introHeart: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bs-into-the-heart-space.mp3',
            hipsOcciputs: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bs-hips-occiputs-sphenoid.mp3',
            psoasMuscle: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bs-psoas-muscle-piriformis-quadratus-lumborum.mp3',
            c1C7: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bs-c1-c7.mp3',
            grounding: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bs-grounding.mp3'
        },
        video: {},
    },
    // QT Chakra balancing *
    cG9zdDo2MzY1: {
        audio: {
            balancing: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/chakra-balancing.mp3'
        },
        video: {},
    },
    // Lunchtime reset
    cG9zdDo3Mzcz: {
        audio: {},
        video: {},
    },
    // Self healing mastery *
    cG9zdDo3NzQz: {
        audio: {
            m1A1: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Track-1-Introduction.mp3',
            m1A2: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Track-2-Hi-Chi-Magic-Mirror.mp3',
            m1A3: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Track-3-Scale-of-Life-Force-Energy.mp3',
            m1A4: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Track-4-Your-Hi-Chi-Lazer.mp3',
            m1A5: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Track-5-Essence-of-Hi-Chi-learn-from-kids.mp3',
            m1A6: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Track-6-Your-Hi-Chi-Mirror-of-Clarity.mp3',
            m1A7: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Track-7-Your-Healing-Potential.mp3',
            m1A8: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Track-8-Review-of-a-Hi-Ch-Life-you-Love.mp3',
            m1A9: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Meditation.mp3',
            m2A1: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-2-Track-1-What-Is-Mindfulness.mp3',
            m2A2: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-2-Track-2-Am-I-Mindful.mp3',
            m2A3: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-2-Track-3-What-happens-to-our-body.mp3',
            m2A4: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-2-Track-4-Hi-Chi-Mindfulness.mp3',
            m2A5: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-2-Track-5-Softening-into-Yin.mp3',
            m2A6: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-2-Track-6-Activating-the-senses.mp3',
            m2A7: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-2-Meditation-1-Hi-Chi-Chillout-Zen-Den.mp3',
            m2A8: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-2-Meditation-Hi-Chi-Body-Scan-Intro.mp3',
            m3A1: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Track-1-Adding-The-Vital-Ingredient.mp3',
            m3A2: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Track-2-Gratitude-The-Vital-Ingredient.mp3',
            m3A3: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Track-3–Why-Change-Your-Viewpoint.mp3',
            m3A4: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Track-4-Gratitude-Overcomes-Resistance.mp3',
            m3A5: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Track-5-What-You-Notice.mp3',
            m3A6: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Track-6-Miracles-Happen-Here-Now.mp3',
            m3A7: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Track-7-Hi-Chi-Amplified.mp3',
            m3A8: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Meditation-1.mp3',
            m3A9: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Meditation-2.mp3',
            m4A1: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Track-1-Breathing-With-Hi-Chi.mp3',
            m4A2: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Track-2-Opening-Your-Breath.mp3',
            m4A3: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Track-3-Oxygenating-Your-Cells.mp3',
            m4A4: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Track-4-We-Are-Being-Breathed.mp3',
            m4A5: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Track-5-The-Universal-Lung.mp3',
            m4A6: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Track-6-Universal-Lung-Breath.mp3',
            m4A7: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Track-7-Breathe-Serene-a-Breath-Of-Oneness.mp3',
            m4A8: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Track-8-The-Grateful-Breath.mp3',
            m4A9: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Meditation.mp3',
            m5A1: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-1-The-Hi-Chi-Magic-Mirror-Of-Love.mp3',
            m5A2: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-2-The-Miracle-Of-Love-Measured.mp3',
            m5A3: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-3-The-Heart-Centre.mp3',
            m5A4: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-4-The-Love-Effect.mp3',
            m5A5: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-5-Health-Improvements.mp3',
            m5A6: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-6-Loving-What-Is.mp3',
            m5A7: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-7-Prayer-To-Open-The-Heart.mp3',
            m5A8: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-8-Breath-Of-Love.mp3',
            m5A9: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-9-Design-Your-Love-Breath.mp3',
            m5A10: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Meditation-1.mp3',
            m5A11: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Meditation-2.mp3',
            m6A1: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-6-Track-1-Hi-Chi-Grounding.mp3',
            m6A2: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-6-Track-2-What-happens-to-our-body-when-we-Earth.mp3',
            m6A3: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-6-Track-3-Hi-Chi-Grounding-with-our-Inner-Child.mp3',
            m6A4: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-6-Track-4-Hi-Chi-Your-Hi-Chi-Self-Healing-Mastery-Menu.mp3',
            m6A5: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-6-Meditation-1.mp3',
            m6A6: 'https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-6-Meditation-2.mp3'
        },
        video: {},
    },
}

export default programmes;