const fixture = [
    {
      programName: "Chakra Balancing Collection",
      featuredImage: "/app/images/chakra-balancing-collection/chakra-balancing-collection.jpg",
      audioFiles: [
        {
            title: "Chakra Intro",
            color: "green",
            image: "/app/images/chakra-balancing-collection/chakra-balancing-collection.jpg",
            duration: "7",
            audioMp3: "https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-intro.mp3"
        },
        {
            title: "Root Chakra",
            color: "red",
            image: "/app/images/chakra-balancing-collection/chakra-balancing-collection.jpg",
            duration: "13",
            audioMp3: "https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-1.mp3"
        },
        {
            title: "Sacral Chakra",
            color: "orange",
            image: "/app/images/chakra-balancing-collection/chakra-balancing-collection.jpg",
            duration: "16",
            audioMp3: "https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-2.mp3"
        },
        {
            title: "Solar Plexus Chakra",
            color: "yellow",
            image: "/app/images/chakra-balancing-collection/chakra-balancing-collection.jpg",
            duration: "26",
            audioMp3: "https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-3.mp3"
        },
        {
            title: "Heart Chakra",
            color: "green",
            image: "/app/images/chakra-balancing-collection/chakra-balancing-collection.jpg",
            duration: "8",
            audioMp3: "https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-4.mp3"
        },
        {
            title: "Throat Chakra",
            color: "blue",
            image: "/app/images/chakra-balancing-collection/chakra-balancing-collection.jpg",
            duration: "19",
            audioMp3: "https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-5.mp3"
        },
        {
            title: "Third eye Chakra",
            color: "indigo",
            image: "/app/images/chakra-balancing-collection/chakra-balancing-collection.jpg",
            duration: "5",
            audioMp3: "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-6.mp3"
        },
        {
            title: "Crown Chakra",
            color: "purple",
            image: "/app/images/chakra-balancing-collection/chakra-balancing-collection.jpg",
            duration: "12",
            audioMp3: "https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-7.mp3"
        },
        {
            title: "Balancing all the Chakras",
            image: "/app/images/chakra-balancing-collection/chakra-balancing-collection.jpg",
            duration: "33",
            audioMp3: "https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/chakra/chakra-balancing.mp3"
        },
      ],
    }
  ]
  
  export default fixture
  




// sound-bell.jpg