import React from "react"
import { Link } from "gatsby"
import {
  SleepNoticeContainer,
  SleepCookieButton,
} from "../styledComponents/sleepNotice"

const ResetAnxietyNotice = ({ isRelaxCookieSet, createRelaxCookie }) => {
  return (
    <>
      <SleepNoticeContainer isRelaxCookieSet={isRelaxCookieSet}>
        <div>
          <h2>Relax Notice</h2>
          <p>Please note that when listening to this program, you must be in somewhere comfortable and relaxed.</p>
          <SleepCookieButton onClick={() => createRelaxCookie('relax-program-info')}>
            I understand
          </SleepCookieButton>
          </div>
      </SleepNoticeContainer>
    </>
  )
}

export default ResetAnxietyNotice
