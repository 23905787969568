import React, { useState } from "react"
import { ModalProvider, BaseModalBackground } from "styled-react-modal"
import styled from "styled-components"
import {
  StyledProgramInfoList
} from "../styledComponents/programInfoList"

import { StyledButton, StyledLinkButton, StyledCloseModalButton } from "../styledComponents/button"
import Icon from "../components/icon"
import { StyledModal } from "../styledComponents/modal"
import { DownloadBanner, DownloadMessage } from "../styledComponents/offlineStorage"
function FancyModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);

  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }

  return (
    <>
      
      <li className="pat-process-instructions" onClick={toggleModal}><svg className="svg-icon" viewBox="0 0 20 20">
              <path d="M8.627,7.885C8.499,8.388,7.873,8.101,8.13,8.177L4.12,7.143c-0.218-0.057-0.351-0.28-0.293-0.498c0.057-0.218,0.279-0.351,0.497-0.294l4.011,1.037C8.552,7.444,8.685,7.667,8.627,7.885 M8.334,10.123L4.323,9.086C4.105,9.031,3.883,9.162,3.826,9.38C3.769,9.598,3.901,9.82,4.12,9.877l4.01,1.037c-0.262-0.062,0.373,0.192,0.497-0.294C8.685,10.401,8.552,10.18,8.334,10.123 M7.131,12.507L4.323,11.78c-0.218-0.057-0.44,0.076-0.497,0.295c-0.057,0.218,0.075,0.439,0.293,0.495l2.809,0.726c-0.265-0.062,0.37,0.193,0.495-0.293C7.48,12.784,7.35,12.562,7.131,12.507M18.159,3.677v10.701c0,0.186-0.126,0.348-0.306,0.393l-7.755,1.948c-0.07,0.016-0.134,0.016-0.204,0l-7.748-1.948c-0.179-0.045-0.306-0.207-0.306-0.393V3.677c0-0.267,0.249-0.461,0.509-0.396l7.646,1.921l7.654-1.921C17.91,3.216,18.159,3.41,18.159,3.677 M9.589,5.939L2.656,4.203v9.857l6.933,1.737V5.939z M17.344,4.203l-6.939,1.736v9.859l6.939-1.737V4.203z M16.168,6.645c-0.058-0.218-0.279-0.351-0.498-0.294l-4.011,1.037c-0.218,0.057-0.351,0.28-0.293,0.498c0.128,0.503,0.755,0.216,0.498,0.292l4.009-1.034C16.092,7.085,16.225,6.863,16.168,6.645 M16.168,9.38c-0.058-0.218-0.279-0.349-0.498-0.294l-4.011,1.036c-0.218,0.057-0.351,0.279-0.293,0.498c0.124,0.486,0.759,0.232,0.498,0.294l4.009-1.037C16.092,9.82,16.225,9.598,16.168,9.38 M14.963,12.385c-0.055-0.219-0.276-0.35-0.495-0.294l-2.809,0.726c-0.218,0.056-0.351,0.279-0.293,0.496c0.127,0.506,0.755,0.218,0.498,0.293l2.807-0.723C14.89,12.825,15.021,12.603,14.963,12.385"></path>
            </svg>Instructions</li>
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        className="order-modal program-instructions"
      >
       <StyledCloseModalButton onClick={toggleModal}>
          <Icon name="cross" />
        </StyledCloseModalButton>
        
        <h2>Permissioning and Transformation™ Instructions </h2>
        
        <h2>Hand to Heart sequence</h2>
        <p>We <strong>always</strong> start each Permissioning™ session with the hand to heart sequence.&nbsp;</p>
        <p>This is to bring you out of the head and into the body. It also relaxes your mind quickly and deeply which makes the Permissions much more effective.&nbsp;</p>
        <h2>The order of the Hand to Heart sequence is:</h2>
        <h3>Step 1- sensing a material</h3>
        <ul><li>Hold out a hand of your choice</li><li>Touch and feel something textured such as the material on a chair or bed as if you were running your hand down textured wallpaper&nbsp;</li><li>Now move the same hand over your heart</li><li>Sense the clothing material under your hand or on your skin</li></ul>
        <h3>Step 2 – sensing the hand and the heart</h3>
        <ul><li>And now from the perspective of your hand become aware of the heart</li><li>Use your intention and focus to imagine that your arm is a tunnel</li><li>In one fluid motion move your focus from your hand, down to the elbow, up to the shoulders, and drop into the heart</li><li>Now your awareness is in the heart</li><li>It is as if you are the heart and you can simply feel the hand there</li><li>Using your intention, move your awareness out of your heart, up to the shoulder, down through the arm, and back to the hand, as if your arm is a tunnel</li><li>From the perspective of the hand – Sense the presence of the heart as if it was something or someone else</li><li>Use your intention and focus again to journey from the hand back through your arm into the heart</li><li>When you arrive at the heart – sense the hand is as if it is a very loving hand that you can feel there</li><li>And then go back to the hand, always traveling through the arm with your intention and focus</li></ul>
        <h4>The summary&nbsp;</h4>
        <ul><li>From the hand feel and sense the presence of the heart&nbsp;</li><li>Now flow to the heart, always moving your focus through the arm</li><li>From the heart feel the calm presence of the hand&nbsp;</li><li>Now flow back to the hand</li></ul>
        <h2>Step 3 – Sending and receiving love</h2>
        <ul><li>Send love and reassurance from the hand into the heart as if you were sending to something or someone else that you can easily feel love for</li><li>Then travel from the hand, through the arm into the heart</li><li>When you arrive at the heart, receive all this love and reassurance as if it were coming from something or someone else</li><li>And then flow from the heart back to the hand</li><li>Now you start the Permissions</li></ul>

      </StyledModal>
    </>
  )
}


function FaqModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);

  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }

  return (
    <>
      <li className="pat-process-faq" onClick={toggleModal} ><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512.001 512.001" ><g>
  <g>
    <path d="M509.989,463.656L474.84,361.363c16.906-34.174,25.847-72.265,25.907-110.593c0.102-66.002-25.435-128.411-71.909-175.727
      C382.357,27.718,320.418,1.08,254.433,0.033c-34.571-0.555-68.117,5.815-99.757,18.912c-30.517,12.633-57.884,30.932-81.34,54.388
      c-23.457,23.456-41.756,50.823-54.388,81.34c-13.097,31.64-19.46,65.203-18.913,99.757
      c1.045,65.985,27.684,127.924,75.009,174.406c47.224,46.383,109.472,71.912,175.332,71.911c0.129,0,0.265,0,0.394,0
      c38.328-0.06,76.419-9.001,110.594-25.907l102.293,35.149c3.934,1.352,7.966,2.011,11.954,2.011
      c9.474,0,18.69-3.722,25.678-10.712C511.218,491.359,514.553,476.939,509.989,463.656z M479.824,479.824
      c-1.007,1.007-3.163,2.535-6.304,1.457l-108.394-37.246c-1.603-0.55-3.27-0.824-4.932-0.824c-2.45,0-4.889,0.593-7.098,1.762
      c-31.327,16.573-66.727,25.363-102.374,25.417c-119.55,0.193-218.442-96.91-220.336-216.441
      C29.433,193.81,52.309,137.289,94.8,94.798c42.491-42.49,98.995-65.362,159.151-64.411
      c119.53,1.895,216.625,100.738,216.439,220.336c-0.056,35.648-8.845,71.048-25.417,102.373c-1.962,3.708-2.301,8.063-0.938,12.03
      l37.246,108.394C482.36,476.659,480.832,478.818,479.824,479.824z"></path>
  </g>
</g>
<g>
  <g>
    <path d="M247.91,362.748c-7.939,0-15.545,6.981-15.178,15.178c0.368,8.223,6.669,15.178,15.178,15.178
      c7.939,0,15.545-6.981,15.178-15.178C262.72,369.702,256.419,362.748,247.91,362.748z"></path>
  </g>
</g>
<g>
  <g>
    <path d="M247.91,127.674c-41.639,0-75.515,33.876-75.515,75.515c0,8.382,6.796,15.178,15.178,15.178s15.178-6.796,15.178-15.178
      c0-24.9,20.259-45.159,45.159-45.159s45.159,20.259,45.159,45.159s-20.259,45.159-45.159,45.159
      c-8.382,0-15.178,6.796-15.178,15.178v61.905c0,8.382,6.796,15.178,15.178,15.178c8.382,0,15.178-6.795,15.178-15.179v-48.259
      c34.389-7.045,60.337-37.54,60.337-73.982C323.425,161.55,289.549,127.674,247.91,127.674z"></path>
  </g>
</g>
</svg>FAQ's</li>
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        className="order-modal program-instructions"
      >
       <StyledCloseModalButton onClick={toggleModal}>
          <Icon name="cross" />
        </StyledCloseModalButton>
        
        <h2>Permissioning and Transformation™ FAQ's </h2>
        
        <ul>
        <li><a href="#q1">What if my hand gets tired?</a></li>
        <li><a href="#q2">Does it matter which hand I use?</a></li>
        <li><a href="#q3">How quickly should I flow through the arm?</a></li>
        <li><a href="#q4">What if my mind finds it hard to focus?</a></li>
        <li><a href="#q5">Should I repeat each Permission?</a></li>
        <li><a href="#q6">How often should I practice Permissioning and Transformation?</a></li>
        <li><a href="#q7">What are the combination audios?</a></li>
        <li><a href="#q8">How can I listen without being online?</a></li>
        
        </ul>

        <h3 id="q1">What if my hand gets tired?</h3>
        <p>You can switch hands at any time. It is important to be comfortable and relaxed.</p>
        <h3 id="q2">Does it matter which hand I use?</h3>
        <p>You can use either hand or even both hands.</p>
        <h3 id="q3">How quickly should I flow through the arm?</h3>
        <p>You can flow at your own pace and what feels comfortable to you.&nbsp; However, if you move too quickly your mind will get distracted so don’t rush the process.&nbsp;</p>
        <h3 id="q4">What if my mind finds it hard to focus?</h3>
        <p>Say the Permissions out loud or in a whisper.&nbsp;</p>
        <h3 id="q5">Should I repeat each Permission?</h3>
        <p>Follow along with the permissions as I am saying them, either out loud or in your mind. As you get more and more sleepy you will find it harder to focus on repeating the permissions. At this point simply say the word “yes” after each one, to signal to your mind to accept the permission.</p>
        <h3 id="q6">How often should I practice Permissioning and Transformation?</h3>
        <p>Ideally everyday. Sleep permissions should be practiced in bed before going to sleep. Daytime permission are best practiced after waking up.</p>
        <h3 id="q7">What are the combination audios?</h3>
        <p>The combination tracks have been created for you so that you can choose to listen to the Short Hand to Heart sequence and have it continue into playing the other Sleep Permission tracks.</p>
        <p>There are also Sleep Permission tracks with different pauses and gaps between each Permission so that you choose the speed which best suits your own timeframes. For example, there is a Conditioning Relaxation track that is available at 3 different speeds. The longest speed has the longest pauses between the permissions.</p>

        <h3 id="q8">How can I listen without being online?</h3>
        <p>At the top of the program you will see the following icon <svg width="27" height="22" xmlns="http://www.w3.org/2000/svg" style={{'margin-top':'0','position':'relative','top':'4px'}}><g fill-rule="evenodd"><path d="M0 8h2.2v11H0zM12 0h2.2v14H12z"/><path d="m5.987 8.242 1.555-1.555 7.071 7.07-1.555 1.556z"/><path d="m18.558 6.687 1.555 1.555-7.07 7.071-1.556-1.555zM24 8h2.2v11H24zM0 19h26.2v2.2H0z"/></g></svg> </p>
        <p>When you click on the download icon this will start downloading the program to your device.</p>
        <p>Whist it is downloading you will see at the bottom of the screen a green progress bar (please be patient if your network connection is slow-they are high quality audios)</p>
        <DownloadBanner style={{'position':'relative','width':'auto','margin-top':'1.6rem','margin-bottom':'1.6rem'}}>
          <DownloadMessage style={{'position':'relative', 'bottom': '0', 'width': 'unset'}}>
            <div class="downloading">
              <span class="custom-arrow" style={{'animation':'none'}}></span>
            </div> Downloading...
          </DownloadMessage>
        </DownloadBanner>
        <p>Once the download has completed you will then see a bin icon. If you click this it will remove it from your phone.</p>
      </StyledModal>
    </>
  )
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
  z-index: 100;
`;
const ProgramInfoList = () => {
return (


    <StyledProgramInfoList>
      <ModalProvider backgroundComponent={FadingBackground}>
        <FancyModalButton  />
      </ModalProvider>
      <ModalProvider backgroundComponent={FadingBackground}>
        <FaqModalButton  />
      </ModalProvider>      
           
          </StyledProgramInfoList>

)
}
export default ProgramInfoList