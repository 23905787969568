const fixture = [
  {
    programName: "Human Tuning Realignment",
    featuredImage: "/images/human-tuning-blueprint-min.jpg",
    audioFiles: [
      {
        title: "Fibromyalgia",
        image: "/images/human-tuning-blueprint-min.jpg",
        audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/fhtj/02-Fibromyalgia.mp3"
      },
      {
        title: "TMJ",
        image: "/images/human-tuning-blueprint-min.jpg",
        audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/fhtj/03-TMJ.mp3"
      },
      {
        title: "Scoliosis & Sciatica",
        image: "/images/human-tuning-blueprint-min.jpg",
        audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/fhtj/05-Scoliosis-Sciatica.mp3"
      },
      {
        title: "Fatigue & Sleep Disturbance Clearing",
        image: "/images/human-tuning-blueprint-min.jpg",
        audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/fhtj/01-Fatigue-Sleep-Disturbance.mp3"
      },
      {
        title: "Head & Skull",
        image: "/images/human-tuning-blueprint-min.jpg",
        audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/fhtj/08-Head-Skull.mp3"
      },
      {
        title: "Ear & Tinnitus",
        image: "/images/human-tuning-blueprint-min.jpg",
        audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/fhtj/13-Ear-Tinnitus.mp3"
      },
      {
        title: "Neck",
        image: "/images/human-tuning-blueprint-min.jpg",
        audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/fhtj/06-Neck.mp3"
      },
      {
        title: "Elbow",
        image: "/images/human-tuning-blueprint-min.jpg",
        audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/fhtj/15-Elbow.mp3"
      },
      {
        title: "Carpal Tunnel Syndrome & Wrist",
        image: "/images/human-tuning-blueprint-min.jpg",
        audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/fhtj/12-Carpal-Tunnel.mp3"
      },
      {
        title: "Hip",
        image: "/images/human-tuning-blueprint-min.jpg",
        audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/fhtj/07-Hip.mp3"
      },
      {
        title: "Knee",
        image: "/images/human-tuning-blueprint-min.jpg",
        audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/fhtj/09-Knee.mp3"
      },
      {
        title: "Ankle",
        image: "/images/human-tuning-blueprint-min.jpg",
        audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/fhtj/04-Ankle.mp3"
      },
      {
        title: "Migraine",
        image: "/images/human-tuning-blueprint-min.jpg",
        audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/fhtj/10-Migraine.mp3"
      },
      {
        title: "Long covid",
        image: "/images/human-tuning-blueprint-min.jpg",
        audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/fhtj/11-Long-Covid.mp3"
      },
      {
        title: "House & Personal Environment Clearing",
        image: "/images/human-tuning-blueprint-min.jpg",
        audioMp3: "https://karinagrant.s3.eu-west-1.amazonaws.com/audio-download/fhtj/14-House-Clearing.mp3"
      },
    ],
  }
]

export default fixture





// sound-bell.jpg